import React from 'react'
import { Container, Row, Col } from "reactstrap";
import './pages.css'
import imgOzias from '../../images/PORTADA-CURSONATURALIZACION-V01.jpg';
import Card from 'react-bootstrap/Card';
import FormsRecomendacion from '../Forms/FormsRecomedacion'
import MediaPlayer from '../Video/MediaPlayer'

const Naturalizacion = () => {
          return (
                  <>
                    <div className='hero-naturalizacion'>
                      <div className='content-naturalizacion'>
                          <p>naturalización</p> 
                          <p>Asi que ya no sois extranjeros ni advenedizos, sino conciudadanos de los santos y miembros de la familia de Dios<span>efesios 2:19</span></p>
                      </div>
                    </div>
                  <section>
                  <Container>
                    <Row>
                      <Col lg="6" md="6">
                        <div className="about__img">
                          <Card>
                            <Card.Header>Curso de Naturalización</Card.Header>
                            <Card.Body>
                              <Card.Title>Te Preparamos para triunfar y para alcanzar el sueño americano</Card.Title>
                              <Card.Text>Te ayudamos a alcanzar tu meta.</Card.Text>
                              <Card.Text>	No tengas miedos, tus dudas se irán y nosotros te guiaremos</Card.Text>
                              <Card.Text>	Te ayudamos a cumplir tu sueño</Card.Text>
                              <Card.Text>Preparándote para un mejor futuro</Card.Text>
                              <Card.Text>Sin esfuerzo, no hay éxito</Card.Text>
                            </Card.Body>
                          </Card>                          
                        </div>
                      </Col>
                      <Col lg="6" md="6">
                      <div className="about__img">
                          <Card>
                            <Card.Header>Curso de Naturalización</Card.Header>
                            <Card.Body>
                              <Card.Title>“Así que ya no sois extranjeros ni advenedizos, sino conciudadanos de los santos y miembros de la familia de Dios”</Card.Title>
                              <Card.Text>Te acompañamos en tu nuevo camino hacia una nueva etapa en tu vida donde tendrás más seguridad, más beneficios, más oportunidades y una mejor calidad de vida. Tendrás la habilidad de viajar sin limitaciones de tiempo. Se te abrirán nuevas oportunidades para peticionar a tu familia y podrás votar y ser parte de la sociedad. Te guiaremos para que puedas tener derechos dentro del territorio americano. Contamos con un curso que te preparará adecuadamente para la entrevista que cambiará tu vida. El curso es de 4 clases, el costo es de $100.00 dólares. Durante este curso intensivo te prepararemos y repasaremos todas las preguntas que encontrarás en el examen y te aconsejaremos cómo combatir los nervios en la entrevista. Conocerás la estructura de la forma N-400. Si no te sientes cómodo en presentarte a tu entrevista solo, la abogada puede acompañarte por un costo extra, para que puedas sentirte más seguro. 
                              </Card.Text>
                            </Card.Body>
                          </Card>                          
                        </div>
                      </Col>
                    </Row>
                 </Container>
                </section>
                  <section>
                    <Container>
                      <Row>
                        <Col lg="6" md="6">
                          <div className="testimonial__wrapper d-flex justify-content-between align-items-center ">
                            <div className="testimonial__img w-100">
                              <img src={imgOzias} alt="" className="w-100" />
                            </div>
                           </div>
                        </Col>
                            <Col lg="6" md="6" className="newsletter">
                              <h2 className="mb-4">Con práctica, todo se puede 	<br />Querer es poder</h2>
                              <h2 className="mb-4">DTB INMIGRANTE LLC.</h2>
                              <h2 className="mb-4">CURSO DE NATURALIZACIÓN</h2>
                            </Col>
                      </Row>
                    </Container>
                  </section>
                  <section>
                  <Container>
                    <Row>
                    <Col lg="6" md="6">
                          <MediaPlayer />
                      </Col>      
                      <Col lg="6" md="6">
                          <FormsRecomendacion />
                      </Col>      
                      </Row>
                      </Container> 
                </section>
                </>
            )
          }


export default Naturalizacion