import React from 'react'
import { Container, Row, Col } from "reactstrap";
import './pages.css'
import imgOzias from '../../images/FINANZAS-01.jpg';
import Accordion from 'react-bootstrap/Accordion';
import imgFinanzas from '../../images/dtb-finanzas.png'
import FormsRecomendacion from '../Forms/FormsRecomedacion'
import MediaPlayer from '../Video/MediaPlayer'

const Finanzas = () => {
  
  
return (
      <>
        <div className='hero'>
          <div className='content'>
             <p>Finanzas</p>
             <p>tu mejor inversión eres tu mismo, no lo pienses más. Invierte en tu futuro. mas buscad primeramente el reino de Dios y su justicia, y todas estas cosas os serán añadidas. <span> mateo 6:33</span></p>
            </div>
        </div>
<section>
<Container>
  <Row>
    <Col lg="6" md="6">
    <div className="about__content">
                <h2>DTB INMIGRANTE LLC</h2>
                <p>
                Para poder contar con un balance positivo y tu estado financiero con nosotros este sano, es importante estar al tanto  y al pendiente de las fechas correspondientes a tus pagos. Sabemos la situación actualmente es cambiante solo te pedimos que nos pudieras mantener al tanto si requirieras de asesoría o atención más especifica como lo es el poder realizar un convenio, promesas de pago o algún otro servicio adicional. Recuerda que lo más importante para nosotros siempre serás tú. Te apoyaremos en la medida dentro de lo posible. Ten en cuenta que hacer un esfuerzo extra para estar al corriente en tus pagos y dar seguimiento, ayuda a que tu proceso continúe sin ningún contratiempo.  
                </p>
            </div>
    </Col>
    <Col lg="6" md="6">
        <div className="acordion">
          <Accordion defaultActiveKey="0" flush>
             <Accordion.Item eventKey="0">
                <Accordion.Header>Tarifas Migratorias</Accordion.Header>
                <Accordion.Body>
                Tenga en cuenta que las tarifas migratorias se le solicitaran a usted en forma de money Order y esto no está incluido en su contrato y se pagara directamente a immigración. <br />Recuerde que la comunicación es sumamente importante para que su caso avance con nosotros.
                </Accordion.Body>
              </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Los métodos de pago son los siguientes</Accordion.Header>
        <Accordion.Body>  
          <ul>Zelle (623)363-5524</ul>
         <ul>Cash (en persona)</ul> 
         <ul>Money order (a nuestro P.O. BOX o en persona)</ul>
          <ul>Deposito directo (a nuestro banco de America) </ul>
         <ul>Por teléfono o en persona con tarjeta de debito o credito  (se cobrara un 3.5%)</ul> 
        <ul>Programa de Beneflex (si califican) </ul>
        </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
      </Col>
      </Row>
    </Container>
</section>
      <Container>
        <Row>
          <Col lg="6" md="6">
             <img src={imgOzias} alt="" className="w-100" />
          </Col>      
          <Col lg="6" md="6">        
          <img src={imgFinanzas} alt="" className="w-100" />        
          </Col>       
        </Row>
      </Container> 
      <section>
    <Container>
      <Row>
      <Col lg="6" md="6">
            <MediaPlayer />
         </Col>      
        <Col lg="6" md="6">
            <FormsRecomendacion />
         </Col>      
         </Row>
         </Container> 
         </section>
    
</>
)
}

export default Finanzas