import React from 'react'
import { Container, Row, Col } from "reactstrap";
import './text.css'
const TextFinish = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="12" md="12">
            <div className="about__content">
            <p>"Mira que te mando que te esfuerces y seas valiente; no temas ni desmayes,
              porque Jehová tu Dios estará contigo dondequiera que vayas” <span className='text-biblic'>Josué 1:9</span></p>                 
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TextFinish