import React from 'react';
import Header from '../header/Header';
import Home from '../Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from '../Footer/Footer';
import Beneflex from './Beneflex';
import Finanzas from './Finanzas'
import Ozias from './Ozias'
import Ingles from './Ingles'
import Naturalizacion from './Naturalizacion'
import Referencias from './Referencias'
import TextFinish from '../TextFinish/TextFinish';
const Pages = () => {
  return (
    <>
    <BrowserRouter>
    <Header />
    <Routes>
    <Route exact path="/" element={<Home /> } /> 
    <Route path='/beneflex' element={<Beneflex />} />
    <Route path='/finanzas' element={<Finanzas />} />
    <Route path='/ozias' element={<Ozias />} />
    <Route path='/curso-de-naturalizacion' element={<Naturalizacion />} />
    <Route path='/curso-de-ingles' element={<Ingles />} />
    <Route path='/referencias' element={<Referencias />} />  
    </Routes>    
    <TextFinish />
    <Footer />
    </BrowserRouter>
      
    </>
  )
}

export default Pages