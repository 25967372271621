import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import "./footer.css";
const footerQuickLinks = [
  {
    display: "Beneflex",
    url: "beneflex",
  },
  {
    display: "Ozias",
    url: "ozias",
  },

  {
    display: "Naturalización",
    url: "curso-de-naturalizacion",
  },

  {
    display: "Cursos de Inglés",
    url: "curso-de-ingles",
  },
  {
    display: "Finanzas",
    url: "finanzas",
  },
  {
    display: "Referencias",
    url: "referencias",
  },
];
const footerInfoLinks = [
  {
    display: "Política de Privacidad ",
    url: "https://www.dtbinmigrante.org/privacy/",
  },
  {
    display: "Membresia",
    url: "",
  },

  {
    display: "Guia al cliente",
    url: "https://www.dtbinmigrante.org/site-map/",
  },
];

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="3" md="6" className="mb-4">
            <h2 className=" d-flex align-items-center gap-1">
              <i class="ri-pantone-line"></i> DTB INMIGRANTE LLC
            </h2>

            <div className="follows">
              <p className="mb-0">REDES SOCIALES.</p>
             <a href="https://www.facebook.com/dtbinmigrante" target="_blank" rel="noreferrer"> <i className='fab fa-facebook-f icon'></i></a>
             <a href="https://www.instagram.com/dtbinmigrantellc/" target="_blank" rel="noreferrer"><i className='fab fa-instagram icon'></i></a>
             <a href="https://www.tiktok.com/@abogadalindafrayre" target="_blank" rel="noreferrer"><i className='fab fa-tiktok icon'></i></a>
             <a href="https://www.youtube.com/channel/UCrIW4hQzJ_bRxD8ZiF9Nhpw" target="_blank" rel="noreferrer"><i className='fab fa-youtube icon'></i></a>
            </div>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Departamento de Bienestar del Cliente </h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Información</h6>
            <ListGroup className="link__list">
              {footerInfoLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6">
            <h6 className="fw-bold">Ponerse en contacto</h6>

            <p>12145 Grand Ave N El Mirage, AZ 85335</p>
            <p> 623 230-3656  </p>
            <p>info@dtbinmigrante-dbc.org</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;