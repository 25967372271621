import React from 'react'
import { Container, Row, Col } from "reactstrap";
import './pages.css'
import imgOzias from '../../images/REFERENCIAS-02.jpg';
import Slider from "react-slick";
import MediaPlayer from '../Video/MediaPlayer';
import Forms from '../Forms/Forms';




const Referencias = () => {
  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
  };
        return (
          <>
              <div className='hero-referencias'>
                <div className='content-referencias'>
                  <p>Referencias</p>
                   <p>porque de la manera que en un cuerpo tenemos muchos miembros, pero no todos los miembros 
                      tienen la misma función, así nosotros, siendo muchos, somos un cuerpo en cristo, y todos miembros los unos de los otros.
                      <span> Romanos 12:4-5</span></p>
                </div>
              </div>
              <section>
               <Container>
                <Row>
                  <Col lg="6" md="6">
                   <div className="about__img">
                    <img src={imgOzias} alt="" className="w-100" />
                   </div>
                   </Col>
                   <Col lg="6" md="6">
                    <div className="about__content">
                      <h2>Departamento De Referencias y Community Partners</h2>
                       <p>
                        Entendemos la situación por la que estás pasando, la incertidumbre de tantas dudas y saber si podrás recibir la asesoría que tanto estás buscando. Contamos con alianzas estratégicas con diferentes empresas, instancias u organizaciones, por lo cual en éste departamento nos enfocamos en poderte contactar con las personas correspondientes a la necesidad que tú tienes. Si bien no disponemos del servicio legal especializado en su caso, podemos ser el puente a otras opciones que sean de ayuda para usted. Es importante que nos pueda brindar toda la  información correspondiente para poderle asistir de una mejor manera, ya que la información que omita nos pudiera limitar a las posibles opciones con quien lo pudieramos contactar.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
          </section>
          <Container>
        <Row>     
      <Col lg="12" md="12" className='slider-referencias'>
      <Slider {...settings}>
              <div>
                <div className="single__testimonial">
                  <h2 className="mb-5 fw-bold">Departamento De Referencias y Community Partners</h2>
                  <p>Nosotros te conectamos con tu futuro.</p>
                </div>
              </div>

              <div>
                <div className="single__testimonial">
                  <h2 className="mb-3 fw-bold">
                  Departamento De Referencias y Community Partners
                  </h2>
                  <p>Si no lo tenemos, lo buscamos para ti</p>
                </div>
              </div>

              <div>
                <div className="single__testimonial">
                  <h2 className="mb-3 fw-bold">Departamento De Referencias y Community Partners
                  </h2>
                  <p>Creando puentes en la comunidad para ti y te contactamos con la ayuda que necesitas.</p>    
                </div>
              </div>
            </Slider>
          </Col>
       
        </Row>
      </Container>
      <section>
    <Container>
      <Row>
      <Col lg="6" md="6">
            <MediaPlayer />
         </Col>      
        <Col lg="6" md="6">
            <Forms />
         </Col>      
         </Row>
         </Container> 
         </section>
         
    
</>
)
}
export default Referencias