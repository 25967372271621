import React, {useState, useRef} from 'react'
import '../../App.css'


const MediaPlayer = () => {
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => {
    const video = videoRef.current;
    isPlaying ? video.pause() : video.play();
    setIsPlaying(!isPlaying);
  }
  return (
    <div className='video'>
      <video width="100%" ref={videoRef} onClick={handlePlay}>
        <source src='videos/video__one.mp4' type='video/mp4'/>
      </video>
      <button onClick={handlePlay}>{ isPlaying ? 'Pause' : 'Play'}</button>

    </div>
  )
}

export default MediaPlayer