import React from 'react'
import { Container, Row, Col } from "reactstrap";
import './pages.css'
import imgIngles from '../../images/CUROSINGLES-01.jpg';
import imgInglesCurso from '../../images/dtb-cursoingles.png';
import Toast from 'react-bootstrap/Toast';
import FormsRecomendacion from '../Forms/FormsRecomedacion'
import MediaPlayer from '../Video/MediaPlayer'

const Ingles = () => {
      return (
      <>
          <div className='hero-ingles'>
            <div className='content-ingles'>
              <p>Curso de Inglés</p>
              <p>Y dijo Jehová: He aquí el pueblo es uno, y todos estos tienen un solo lenguaje; y han comenzado la obra, y nada les hará desistir ahora de lo que han pensado hacer.<span>génesis 11:6</span></p>
            </div>
          </div>
          <section>
            <Container>
             <Row>
              <Col lg="6" md="6">
                <div className="about__img">
                  <Toast>
                        <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">CURSO DE INGLÉS</strong>
                        <small>GÉNESIS 11:6</small>
                      </Toast.Header>
                      <Toast.Body>¿Por qué estar bien, cuándo puedes estar mejor?</Toast.Body>
                      <Toast.Body>Derrumba las barreras</Toast.Body>
                      <Toast.Body>No te limites</Toast.Body>
                      <Toast.Body>Más conocimiento, más oportunidades</Toast.Body>
                      <Toast.Body>Abre la puerta a un mejor futuro</Toast.Body>
                       <Toast.Body>No tengas miedo de aprender algo nuevo, ve más allá de tu lenguaje y podrás abrir los ojos a un nuevo horizonte 
                      </Toast.Body>
                    </Toast>        
                </div>
              </Col>
              <Col lg="6" md="6">
              <img src={imgInglesCurso} alt="" className="w-100" />
              </Col>
            </Row>
          </Container>
        </section>
         <section>       
          <Container>
            <Row>
              <Col lg="6" md="6">
                <img src={imgIngles} alt="" className="w-100" />
              </Col> 
              <Col lg="6" md="6">
              <Toast className='toast'>
                        <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">CURSO DE INGLÉS</strong>
                        <small>GÉNESIS 11:6</small>
                      </Toast.Header>
                      <Toast.Body>-	El Inglés es el Idioma universal por excelencia, es hablado en casi todos los países del mundo y se ha convertido una necesidad aprenderlo. En este curso podrás obtener las herramientas necesarias para desenvolverte mejor en tu vida diaria y laboral. Aprendiendo vocabulario necesario para las diferentes situaciones que se te puedan presentar en tu vida diaria teniendo una aplicación inmediata. Podrás realizar una orden en un restaurante, ir a una consulta médica, realizar tus compras en el supermercado y muchas situaciones que se te pueden presentar diariamente. 
                      </Toast.Body>
                    </Toast>   
                  </Col>
                </Row>
              </Container>
            </section>
            <section>
          <Container>
            <Row>
            <Col lg="6" md="6">
                  <MediaPlayer />
              </Col>      
              <Col lg="6" md="6">
                  <FormsRecomendacion />
              </Col>      
              </Row>
              </Container> 
         </section>

</>
)
}
export default Ingles