import React from 'react'
import { Container, Row, Col } from "reactstrap";
import './pages.css'
import imgOzias from '../../images/img-ozias-02.jpg';
import Card from 'react-bootstrap/Card';
import FormsRecomendacion from '../Forms/FormsRecomedacion'
import MediaPlayer from '../Video/MediaPlayer'



const Ozias = () => {
        return (
         <>
          <div className='hero-ozias'>
            <div className='content-ozias'>
               <p>ozias</p>
               <p>si sientes que el mundo se te viene encima, déjanos ser la fuerza que te hace falta.
                "porque yo sé los pensamientos que tengo acerca de vosotros, dice jehová, pensamientos de paz, y no de mal.
                para daros el fin que esperias. <span>jeremias 29:11</span> </p>
              </div>
          </div>
    <section>
    <Container>
      <Row>
        <Col lg="6" md="6">
          <div className="about__img">
            <img src={imgOzias} alt="" className="w-100" />
          </div>
        </Col>
        <Col lg="6" md="6">
                        <div className="about__img">
                          <Card>
                            <Card.Header>Lenguaje para OZIAS</Card.Header>
                            <Card.Body>
                              <Card.Title>Bienvenido a OZIAS, donde podrás dejar un poco de la carga que hay sobre tus hombros</Card.Title>
                              <Card.Text>¿Necesitas hablar? En OZIAS podemos escucharte y apoyarte en momentos difíciles.</Card.Text>
                              <Card.Text>Cuando ya no puedas más, nosotros estamos para ti</Card.Text>
                              <Card.Text>¿Necesitas apoyo? Puedes contar con nosotros</Card.Text>
                              <Card.Text>Siempre dispuestos a escucharte</Card.Text>
                              <Card.Text>¿Necesitas ser escuchado? Estamos aquí para ti</Card.Text>
                            </Card.Body>
                          </Card>                          
                        </div>
                      </Col>
                  </Row>
             </Container>
        </section>
              <section>
                <Container className="newsletter">
                  <Row>
                    <Col lg="12" className="text-center">
                      <h2 className="mb-4">No estás siempre solo</h2>
                      <h2 className="mb-4">	Estamos aquí para escuchar tu silencio</h2>
                      <h2 className="mb-4">Si sientes que el mundo se te viene encima, dejanos ser la fuerza que te hace falta.</h2>
                      <h2 className="mb-4">Ozias está cuando apenas puedes solo, la idea es que estamos nosotros</h2>
                      <h2 className="mb-4">	Déjanos ser la fuerza que te hace falta</h2>
                      <h2 className="mb-4">¿Necesitas un amigo? En ozias lo has encontrado</h2>
                      <h2 className="mb-4">	Hablando solos, escuchando juntos</h2>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section>
                <Container>
                  <Row>
                  <Col lg="6" md="6">
                        <MediaPlayer />
                    </Col>      
                    <Col lg="6" md="6">
                        <FormsRecomendacion />
                    </Col>      
                    </Row>
                    </Container> 
              </section>
            </>
            )
          }

export default Ozias