import React from 'react'
import Pages from './components/pages/Pages'

const App = () => {
  return (
    <div>
      <Pages />     
    </div>
  )
}

export default App