import React from 'react'
import About from '../About/About'
import List from '../Cards/List'
import Intro from '../intro/Intro'
import FormsRecomendacion from '../Forms/FormsRecomedacion'
import MediaPlayer from '../Video/MediaPlayer'
import { Container, Row, Col } from "reactstrap";

const Home = () => {
  return (
    <>
      <Intro />
      <About />
      <List />
      <section>
    <Container>
      <Row>
      <Col lg="6" md="6">
            <MediaPlayer />
         </Col>      
        <Col lg="6" md="6">
            <FormsRecomendacion />
         </Col>      
         </Row>
         </Container> 
         </section>
    </>
  )
}

export default Home