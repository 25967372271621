import React, { useState } from "react";
import './form.css'
const FormsRecomendacion = () => {
  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    "entry.17685031": "",
    "entry.582760896": "",
    "entry.203011200": "",
    "entry.1587446240": "",
    "entry.1156458658": ""
  });

  const handleInputData = (input) => (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmit(true);

    let url = `https://docs.google.com/forms/u/0/d/e/1FAIpQLSeU42y_FQxfeQNhCR8xYQhSVGECcDYsORtfwWnO-a83EHys0A/formResponse?entry.17685031=${formData["entry.17685031"]}&entry.582760896=${formData["entry.582760896"]}&entry.203011200=${formData["entry.203011200"]}&entry.1587446240=${formData["entry.1587446240"]}&entry.1156458658=${formData["entry.1156458658"]}`;

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  }
  return (
    <div className="contactFormWrapper">
      <div className="formheader"></div>
      <div className="formcontact">
        {submit ? (
          <div className="afterForm">Conexiones DTB</div>
        ) : (
          <form onSubmit={handleSubmit} target="_self">
            <div className="form">
              <h1 className="titulo-form">¡Recomiéndanos!</h1>
              <p className="subtitulo-form">¿Tienes un familiar o amigo que necesite nuestros servicios?</p>
              <div className="grupo">
                <input 
                type="text"
                name="entry.17685031"
                onChange={handleInputData("entry.17685031")}
                value={formData["entry.17685031"]}
                autoComplete={false}              
                />
                <span className="barra"></span>
                <label>Nombre de tu amigo o familiar, etc...</label>
              </div>
              <div className="grupo">
                <input 
                type="text"
                name="entry.582760896"
                onChange={handleInputData("entry.582760896")}
                value={formData["entry.582760896"]}
                autoComplete={false} 
                />
                <span className="barra"></span>
                <label>Numero de Teléfono de tu amigo o familiar, etc... </label>
              </div>
              <div className="grupo">
                <input 
                type="text"
                name="entry.203011200"
                onChange={handleInputData("entry.203011200")}
                value={formData["entry.203011200"]}
                autoComplete={false}                 
                />
                <span className="barra"></span>
                <label>Email de tu amigo o familiar, etc... </label>
              </div>
              <div className="grupo">
                <input 
                type="text"
                name="entry.1587446240"
                onChange={handleInputData("entry.1587446240")}
                value={formData["entry.1587446240"]}
                autoComplete={false} 
                />
                <span className="barra"></span>
                <label>Mensaje: </label>
              </div>
              <div className="grupo">
                <input 
                type="text"
                name="entry.1156458658"
                onChange={handleInputData("entry.1156458658")}
                value={formData["entry.1156458658"]}
                autoComplete={false} 
                />
                <label>¿Quién nos comparte la referencia?  </label>
              </div>
            </div>
           <button type="submit">Enviar</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default FormsRecomendacion;
