import React, { useState } from "react";
import './form.css'
const Form = () => {
  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    "entry.475492078": "",
    "entry.1715788072": "",
    "entry.1045539569": "",
    "entry.1905027530": "",
    "entry.2146915870": ""
  });

  const handleInputData = (input) => (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmit(true);

    let url = `https://docs.google.com/forms/u/0/d/e/1FAIpQLSeh5HtGjwbN0bfnTo4FS9L0KZsNTtwc5uy8oyvthb_Yrlkx1A/formResponse?entry.475492078=${formData["entry.475492078"]}&entry.1715788072=${formData["entry.1715788072"]}&entry.1045539569=${formData["entry.1045539569"]}&entry.1905027530=${formData["entry.1905027530"]}&entry.2146915870=${formData["entry.2146915870"]}`;

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  }
  return (
    <div className="contactFormWrapper">
      <div className="formheader"></div>
      <div className="formcontact">
        {submit ? (
          <div className="afterForm">Conexiones DTB</div>
        ) : (
          <form onSubmit={handleSubmit} target="_self">
            <div className="form">
              <h1 className="titulo-form">Conexiones DTB</h1>
              <p className="subtitulo-form">Necesitas una referencia para otro servicio como: Comida de emergencia, asistencia médica, otro tipo de abogado, iglesia, etc…</p>
              <div className="grupo">
                <input 
                type="text"
                name="entry.475492078"
                onChange={handleInputData("entry.475492078")}
                value={formData["entry.475492078"]}
                autoComplete={false}              
                />
                <span className="barra"></span>
                <label>Nombre Completo: </label>
              </div>


              <div className="grupo">
                <input 
                type="text"
                name="entry.1715788072"
                onChange={handleInputData("entry.1715788072")}
                value={formData["entry.1715788072"]}
                autoComplete={false} 
                />
                <span className="barra"></span>
                <label>Correo Electrónico: </label>
              </div>
              <div className="grupo">
                <input 
                type="text"
                name="entry.1045539569"
                onChange={handleInputData("entry.1045539569")}
                value={formData["entry.1045539569"]}
                autoComplete={false}                 
                />
                <span className="barra"></span>
                <label>Número de Teléfono: </label>
              </div>
              <div className="grupo">
                <input 
                type="text"
                name="entry.1905027530"
                onChange={handleInputData("entry.1905027530")}
                value={formData["entry.1905027530"]}
                autoComplete={false} 
                />
                <span className="barra"></span>
                <label>¿Horario para contactarte? </label>
              </div>
              <div className="grupo">
                <input 
                type="text"
                name="entry.2146915870"
                onChange={handleInputData("entry.2146915870")}
                value={formData["entry.2146915870"]}
                autoComplete={false} 
                />
                <label>Explique su necesidad para apoyarle de una mejor manera: </label>
              </div>

            </div>
            
            <button type="submit">Enviar</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Form;
