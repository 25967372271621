import React from "react";
import { Container, Row, Col } from "reactstrap";
import Img01 from '../../images/beneflex.jpg';
import Img02 from '../../images/ozias.jpg';
import Img03 from '../../images/naturalizacion.jpg';
import Img04 from '../../images/ingles.jpg';
import Img05 from '../../images/finanzas.jpg';
import Img06 from '../../images/referencias.jpg';
import ListCard from "./ListCard";
import './list.css'
import { Link } from "react-router-dom"


const coursesData = [
    {
      id: "01",
      title: "Beneflex",
      imgUrl: Img01,
      url: '/beneflex',
     

    },
    {
      id: "02",
      title: "Ozias",
      imgUrl: Img02,
      url: "/ozias",
           
    },
  
    {
      id: "03",
      title: "Cursos de Naturalización",
      imgUrl: Img03,
      url: "/curso-de-naturalizacion",
    },
  
    {
      id: "04",
      title: "Cursos de Inglés",
      imgUrl: Img04,
      url: "/curso-de-ingles",
    },
    {
        id: "05",
        title: "Finanzas",
        imgUrl: Img05,
        url: "/finanzas",
      },
    
      {
        id: "06",
        title: "Referencias",
        imgUrl: Img06,
        url: "/referencias",
      },
  ];
  
  const List = () => {
    return (
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <div className="course__top d-flex justify-content-between align-items-center">
                <div className="course__top__left">
                  <h2>“Mas buscad primeramente el reino de Dios y su justicia, y todas estas cosas os serán añadidas.“ <em className='text-biblic'>Mateo 6:33</em></h2>
                  <p>Para poder contar con un balance positivo y tu estado financiero con nosotros esté sano, es importante estar al tanto  y al pendiente de las fechas correspondientes a tus pagos. Sabemos que la situación actualmente es cambiante, sólo te pedimos que nos mantengas al tanto si requirieras asesoría o atención más específica como lo es el poder realizar un convenio, promesas de pago o algún otro servicio adicional. Recuerda que lo más importante para nosotros siempre serás tú.<br /> Ten en cuenta que hacer un esfuerzo extra para estar al corriente en tus pagos y dar seguimiento, ayuda a que tu proceso continue sin ningún contratiempo. 
                  </p>
                </div>
              </div>
            </Col>
            <h2 className="course__top__left">Te apoyaremos dentro de nuestras posibilidades.</h2>
            {coursesData.map((item) => (
              <Col lg="4" md="6" sm="6">
                <ListCard key={item.id} item={item} />
                <Link to={item.url}></Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    );
  };
  
  export default List;