import React from 'react'
import "./about.css"
import AboutHome from "../../images/About-.jpg"
import LogoHome from "../../images/logollc.png"


const About = () => {
  return (
    <div className='a'>
      <div className='a-left'>
        <div className='a-card bg'></div>
        <div className='a-card'>
          <img src={AboutHome} alt="" className='a-img' />
        </div>
      </div>
    <div className='a-right'>
      <h1 className='a-title'>DTB INMIGRANTE LLC</h1>
        <p className='a-sub'>Si sientes que el mundo se te viene encima, déjanos ser la fuerza que te hace falta.</p>
        <p className='a-sub'>Te preparamos para triunfar y para alcanzar el sueño americano.</p>
        <p className='a-sub'>No tengas miedo de aprender algo nuevo, ve más allá de tu lenguaje y podrás abrir los ojos a un nuevo horizonte.</p>                    
      <div className='a-award'>
        <img src={LogoHome} alt="" className='a-award-img'/>
        <div className='a-award-text'>
          <h4 className='a-award-title'>
          Tu mejor inversión eres tu mismo, no lo pienses más.<br />Invierte en tu futuro.
          </h4>
        </div>
      </div>
      </div>
    </div>
  )
}

export default About